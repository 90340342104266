import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import BlogList from "../components/blog-list"
import Seo from "../components/seo"
import Link from "../components/link"


const BlogIndexPage = (props) => {
	let blogPosts = props.data.tidealcms.listBlogPosts.data;
	let featuredPost = blogPosts[0];

	const published = featuredPost.published
		? new Intl.DateTimeFormat('en-GB').format(new Date(featuredPost.published))
		: null;
	
	return (
  <Layout>
    <Seo 
    	title="Blog - Tideal"
    	url="/blog/"
    />
    <div id="blogIndex">
    	<section>
		    <h1 className="pageTitle">Tideal stories</h1>
		    <h2 className="blogIntro">Greetings and articles from the Tideal people</h2>
		    <div className="blog-list">
			    <Link to={"/blog/"+featuredPost.slug} className="blog-list-item featured">
			    	<div className="image" style={ { backgroundImage: 'url(' + featuredPost.image + ')'} } />
			    	<div className="content">
				    	<h4 className="featuredText">Most recent</h4>
				    	<h1>{featuredPost.title}</h1>
				    	<p>{featuredPost.ingress}</p>
				    	<div className="details">
				    		<span className="category">{featuredPost.category}</span>
								{ published && <span className="date">	• {published}</span>}
				    	</div>
				    </div>
			    </Link>
			  </div>
			  <BlogList blogPosts={blogPosts} dontShow={featuredPost} />	
		  </section>
		</div>
  </Layout>
  )
}

export default BlogIndexPage

export const query = graphql`{
  tidealcms {
    listBlogPosts {
      data {
        id,
        slug,
        title,
        ingress,
        category,
        theme,
        image,
        appHighlight,
        published
      }
    }
  }
}`
